import React, { useEffect, useState, useContext } from "react";
import fruitIcon from "../assets/icons/fruits.png";
import vegeIcon from "../assets/icons/vegetables.png";
import offersIcon from "../assets/icons/offers.png";
import ryanIcon from "../assets/icons/ryans.png";
import spiceIcon from "../assets/icons/spice.png";
import stapleIcon from "../assets/icons/staple.png";
import meatFishIcon from "../assets/icons/meat-fish.png";
import Styles from "./styles/product.module.css";
import clsx from "clsx";
import SpinLoader from "./SpinLoader";
import { CategoriesContext, ProductContext } from "../utils/UserContext";
import { fetchGroceries } from "../utils/userHandler";

import ProductCard from "./ProductCard";
import { Link } from "react-router-dom";
import NoResults from "./NoResults.component";

const Products = () => {
 
  ///loading category from database...
  const [activeTab, setActiveTab] = useState(0);
  const { groceries, setGroceries } = useContext(ProductContext);
  const [activeGroceryList, setActiveGroceryList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const {categories} = useContext(CategoriesContext);


  useEffect(() => {
    if (categories.length > 0 && groceries.length === 0) {
      setIsFetching(true);
      fetchGroceries(setGroceries, setIsFetching);
    } else {
      const filterInitialList = groceries.filter(
        (grocery) => grocery.productCategory === categories[0].id
      );
      setActiveGroceryList(filterInitialList);
    }
  }, [categories,groceries]);




  const onActiveTabChange = (filterString) => {
      const activesList = groceries.filter((grocery) =>
        grocery.productCategory === filterString
      );
      setActiveGroceryList(activesList);
  };

  return (
    <>
      <div className="container text-center mt-5">
        <h4 className="">Discover Our Products</h4>
        <p className="text-muted small">Delivered fresh to your doorstep.</p>
        {categories.length > 0  ? (
          <div
            className={clsx(
              Styles.tabs_wrapper,
              "d-flex align-items-center justify-content-between mt-5 hide-scrollbar"
            )}
          >
            {categories.map((tab, index) => (
              <div
                className={clsx(
                  activeTab === index ? Styles.activeTab : "",
                  Styles.tab,
                  "d-flex align-items-center justify-content-center flex-column pb-2 pointer"
                )}
                key={tab.id}
                onClick={() => {
                  setActiveTab(index);
                  onActiveTabChange(tab.id);
                }}
              >
                <img src={tab.imageUrl} width="100px" alt={tab.title} />
                <div className="small text-capitalize">{tab.name}</div>
              </div>
            ))}
          </div>
        ) : (
          <SpinLoader />
        )}
        {isFetching === true ? (
          <SpinLoader />
        ) : (
          <>
            {activeGroceryList.length === 0 ? (
              <NoResults />
            ) : (
              <ProductCard groceries={activeGroceryList.slice(0)} />
            )}
            <div className="text-center">
              <Link
                to="/products"
                className="btn btn-sm btn-primary text-uppercase"
              >
                <span className="small">View all products</span>
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Products;
