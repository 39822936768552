import { auth, database, storage } from "./firebaseConfig";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  OAuthCredential,
} from "firebase/auth";
import {
  ref,
  set,
  get,
  onValue,
  remove,
  update,
  orderByChild,
  equalTo,
  query,
  runTransaction,
} from "firebase/database";
import {
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { uid } from "uid";
import clsx from "clsx";

export const loginWithEmailAndPassword = async (
  formData,
  currentUser,
  setCurrentUser
) => {
  let res;
  try {
    await signInWithEmailAndPassword(
      auth,
      formData.email,
      formData.password
    ).then((user) => {
      setCurrentUser(user.user);
      getUserData(user.user.uid, currentUser, setCurrentUser);
    });

    res = { status: "success" };
    return res;
  } catch (error) {
    res = { status: "failed", code: error.code };
    return res;
  }
};

export const signupUserWithEmailAndPassword = async (formData) => {
  let resp;
  try {
    let data = await createUserWithEmailAndPassword(
      auth,
      formData.email,
      formData.password
    );
    await storeUserData(data.user, formData);

    resp = { status: "success" };
    return resp;
  } catch (error) {
    resp = { status: "failed", errorCode: error.code };
    return resp;
  }
};

export const storeUserData = async (user, formData) => {
  formData.userId = user.uid;
  formData.token = user.accessToken;
  formData.accountType = "Customer";
  formData.imageUrl = "default";

  let hour = new Date().getHours() % 12;
  let date = new Date().toLocaleDateString();
  let amPm = hour >= 12 ? "PM" : "AM";

  formData.joinDate =
    date + " " + hour + ":" + new Date().getMinutes() + " " + amPm;

  delete formData.password;
  delete formData.confirmPassword;
  set(ref(database, "Users/" + user.uid), formData);
};

export const getUserData = async (uid, currentUser, setCurrentUser) => {
  try {
    onValue(
      ref(database, "Users/" + uid),
      (snapshot) => {
        const {
          userName,
          address,
          adminArea,
          locality,
          imageUrl,
          countryName,
          phoneNumber,
          userId,
          latLng = null,
        } = snapshot.val();

        setCurrentUser({
          email: currentUser.email,
          displayName: userName,
          uid,
          address,
          adminArea,
          locality,
          imageUrl,
          countryName,
          phoneNumber,
          userId,
          latLng,
        });
      },
      { onlyOnce: true }
    );
  } catch (error) {
    console.log(error);
  }
};

const dateTimeString = () => {
  let hours = new Date().getHours() % 12;
  let minutes = new Date().getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";

  return (
    new Date().toLocaleDateString() + " " + hours + ":" + minutes + " " + ampm
  );
};

export const addToCart = async (currentUser, quantity = 1, product, toast) => {
  let response = "";
  let itemTotal = parseInt(product.itemPrice) * quantity;

  try {
    const cartItem = {
      itemCount: quantity,
      itemId: product.itemId,
      cartId: uid(16),
      itemTotal,
      itemPrice: parseInt(product.itemPrice),
      itemOwner: currentUser.uid,
      timeStamp: Date.now(),
      postDate: dateTimeString(),
    };
    set(ref(database, `Cart/${currentUser.uid}/${product.itemId}`), {
      ...cartItem,
    });
    toast.success(`${product.itemName} added to cart`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    response = { status: "success" };
    return response;
  } catch (error) {
    response = { status: "failed", code: error.code };
    return response;
  }
};

export const ReOrderItems = async (uid, cart) => {
  Object.values(cart).forEach((cartItem) => {
    cartItem.timeStamp = Date.now();
    cartItem.postDate = dateTimeString();
  });

  await set(ref(database, "Cart/" + uid), cart);
};

export const getCartItems = (uid, setCartItems) => {
  try {
    onValue(ref(database, `Cart/${uid}`), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let cartItems = Object.values(data).map((item) => item);
        setCartItems({ original: data, items: cartItems });
      } else {
        setCartItems({ original: null, items: [] });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteCart = async (uid) => {
  let response = null;
  try {
    await remove(ref(database, `Cart/${uid}`));
    response = { status: "successful" };
    return response;
  } catch (error) {
    response = { status: "failed", code: error.code };
    return response;
  }
};

export const fetchGroceries = (setGroceries, setFetching) => {
  const list = [];
  try {
    onValue(
      ref(database, "Groceries"),
      (snapshot) => {
        const data = snapshot.val();

        if (data !== null) {
          Object.values(data).map((grocery) => {
            list.push(grocery);
          });
        }
        setFetching(false);
        setGroceries(list);
      },
      { onlyOnce: true }
    );
  } catch (error) {
    console.log(error);
  }
};

export const storeOrder = async (orderId, payload) => {
  let response = "";
  try {
    await set(ref(database, `Orders/${orderId}`), payload);
    response = { status: "successfull" };
    return response;
  } catch (error) {
    response = { status: "failed", code: error.code };

    return response;
  }
};

export const fetchUserOrders = async (userId, setOrdersList) => {
  try {
    const newOrderList = [];

    const dbRef = query(
      ref(database, "Orders"),
      orderByChild("userId"),
      equalTo(userId)
    );
    const fieldExists = await get(dbRef).then((snapshot) => snapshot.exists());

    if (!fieldExists) {
      setOrdersList([]);
      return;
    }

    onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        Object.values(snapshot.val()).forEach((snap) => {
          newOrderList.push(snap);
        });
        newOrderList.sort(
          (a, b) =>
            new Date(b.date.split(" ")[0].split("/").reverse().join("/")) -
            new Date(a.date.split(" ")[0].split("/").reverse().join("/"))
        );

        setOrdersList([...newOrderList]);
      } else setOrdersList([]);
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchCategories = async (setCategories) => {
  try {
    const categoriesSnapshot = await get(ref(database, "categories"));
    let categories = [];

    if (categoriesSnapshot.exists()) {
      categoriesSnapshot.forEach((snapshot) => {
        categories.push(snapshot.val());
      });

       categories.sort((a, b) => a.arrangement - b.arrangement);

        setCategories(categories);
    }
       

   
  } catch (error) {
    console.log(error);
  }
};
export const updateUserPicture = async (
  uid,
  image,
  setUploading,
  setCurrentUser
) => {
  let response = null;

  try {
    setUploading((prevState) => ({ ...prevState, status: true }));
    const uploadTask = uploadBytesResumable(
      storageRef(storage, "images/" + uid),
      image
    );
    let progress = 0;

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        progress =
          Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploading((prevState) => ({ ...prevState, progress }));
      },
      (error) => {
        response = { status: "failed", code: error.code };
        setUploading((prevState) => ({ ...prevState, status: false }));
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          update(ref(database, "Users/" + uid), { imageUrl: downloadUrl });
          setCurrentUser((userData) => ({
            ...userData,
            imageUrl: downloadUrl,
          }));

          setUploading((prevState) => ({ ...prevState, status: false }));
          response = { status: "successful" };
        });
      }
    );

    return response;
  } catch (error) {
    response = { status: "failed", code: error.code };
    return response;
  }
};

export const updateUserData = async (uid, payload) => {
  let response = null;
  const {
    displayName: userName,
    phoneNumber,
    adminArea,
    locality,
    countryName,
    address,
    accountType,
    email,
    joinDate,
    token,
    userId,
  } = payload;
  try {
    update(ref(database, "Users/" + uid), {
      userName,
      phoneNumber,
      adminArea,
      locality,
      countryName,
      address,
      accountType,
      email,
      joinDate,
      token,
      userId,
      uid,
    });

    response = { status: "successful" };
    return response;
  } catch (error) {
    response = { status: "failed", code: error.code };
    return response;
  }
};

export const removeItemFromCart = async (cartId, itemId) => {
  let response = "";
  try {
    await remove(ref(database, `Cart/${cartId}/${itemId}`));
    response = { status: "successful", message: "Item removed from cart" };

    return response;
  } catch (error) {
    response = { status: "failed", code: error.code };
  }
};

export const incrementItem = async (cartId, itemId) => {
  try {
    const dbRef = ref(database, `Cart/${cartId}/${itemId}`);

    runTransaction(dbRef, (transaction) => {
      if (!transaction) return;

      let total = transaction.itemPrice * (transaction.itemCount + 1);

      update(dbRef, {
        itemCount: transaction.itemCount + 1,
        itemTotal: total,
      });
    });
  } catch (error) {
    console.log(error);
  }
};
export const decrementItem = async (cartId, itemId) => {
  try {
    const dbRef = ref(database, `Cart/${cartId}/${itemId}`);
    runTransaction(dbRef, (transaction) => {
      if (!transaction) return;
      if (transaction.itemCount === 1) return;

      let total = transaction.itemPrice * (transaction.itemCount - 1);
      update(dbRef, { itemCount: transaction.itemCount - 1, itemTotal: total });
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateUserLocation = async (userId, coordinates) => {
  try {
    await update(ref(database, "Users/" + userId), { latLng: coordinates });
  } catch (error) {
    console.log(error);
  }
};

export const getBlogs = async (setBlogs) => {
 
  try {
    onValue(ref(database, "Blogs/"),(snapshot) => {
      const blogData = [];
      if(snapshot.val()){
        const data = snapshot.val();
        Object.values(data).map(d => {
          blogData.push(d);
        });
        blogData.sort((a,b) => b.postedDate - a.postedDate);
        setBlogs([...blogData]);
      }
    })
  } catch (error) {
    console.log(error);
  }
};
