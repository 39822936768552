import React, { useContext, useState, useEffect } from "react";
import styles from "./styles/styles.module.css";
import { fetchCategories, getUserData } from '../../utils/userHandler'
import clsx from "clsx";
import { CategoriesContext, UserContext } from "../../utils/UserContext";
import SearchBar from "./widgets/SearchBar";
import LogoWidget from "./widgets/Logo.widget";
import UserAccountWidget from "./widgets/UserAccount.widget";
import { useHistory } from 'react-router-dom';
import Cart from "../Cart";


const MainNav = () => {
  const history = useHistory();
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const { categories, setCategories } = useContext(CategoriesContext)
     useEffect(() => {
       if (currentUser) {
         getUserData(currentUser.uid, currentUser, setCurrentUser);
       }

     }, [currentUser?.displayName]);

     useEffect(() => {
       if (categories.length === 0) {
         fetchCategories(setCategories);
       }
     },[]);

    //  useEffect(() => {
    //   if(!currentUser?.userId){
    //     if(!currentUser?.email){
    //       history.push({
    //         pathname: "/customer/account",
    //         state: {
    //           message:
    //             "Please improve your profile by completing the form below to get a better user experience",
    //         },
    //       });
    //     }
    //   }

    //  },[currentUser])
console.log(categories)
  return (
    <div className={clsx(styles.header)}>
      <div className={clsx(styles.container, "row py-2 align-items-center")}>
        <div className="col-sm-12 col-md-2">
          <LogoWidget />
        </div>
        <div className="col-sm-12 col-md-5 ">
          <SearchBar />
        </div>
        <div className="col-sm-12 col-md-2">
          <div className={clsx(styles.contact, "d-flex align-items-center flex-wrap")}>
            <span className={clsx(styles.callBtn, "material-icons-outlined")}>
              phone_in_talk
            </span>
            <div className="ms-2">
              <div className={clsx(styles.call_, "text-uppercase text-muted")}>
                call to order
              </div>
              <div className={styles.call}>0772 879 984</div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 ">
          <div className="d-flex align-items-center">
      <Cart currentUser={currentUser} />
          <UserAccountWidget currentUser={currentUser} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav;
